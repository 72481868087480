import axios from '@/jslib/axios'

export const getReferrerList = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/list',
    params
  })
}


export const createReferrer = data => {
  return axios({
    method: 'post',
    url: '/admin/referrer',
    data
  })
}

export const editReferrer = data => {
  return axios({
    method: 'patch',
    url: '/admin/referrer/edit',
    data
  })
}


export const getReferrerDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/detail',
    params
  })
}
export const getReferrerUnsettleSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/unsettle/summary',
    params
  })
}
export const getReferrerPendingSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/pending/summary',
    params
  })
}
export const getReferrerSettleSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/settle/summary',
    params
  })
}
export const getReferrerSettle = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/settle',
    params
  })
}

export const setReferrerSettle = data => {
  return axios({
    method: 'post',
    url: '/admin/referrer/settle',
    data
  })
}
export const setReferrerUndo = data => {
  return axios({
    method: 'post',
    url: '/admin/referrer/undo/new',
    data
  })
}
export const getReferrerRecord = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/record',
    params
  })
}
export const getReferrerUnsettleRecord = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/unsettle',
    params
  })
}
export const getReferrerPending = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/pending',
    params
  })
}
export const getReferrerStatement = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/statement',
    params
  })
}
export const referrerStatementDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/statement/detail',
    params
  })
}
export const referrerUndo = data => {
  return axios({
    method: 'post',
    url: '/admin/referrer/undo',
    data
  })
}
export const getReferrerReports = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/reports',
    params
  })
}
export const getReferrerRecordList = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/record/new',
    params
  })
}
export const getReferrerSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/list/summary',
    params
  })
}

export const referrerReportDown = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/reports/download',
    responseType: 'blob',
    params
  })
}
export const referrerRecordDown = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/record/download',
    responseType: 'blob',
    params
  })
}
export const referrerStatementDown = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/statement/download',
    responseType: 'blob',
    params
  })
}
export const referrerSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/referrer/statement/detail/summary',
    params
  })
}
