<template>
  <section class="policy-list">
    <div class="show-table-maxscreen">
      <el-table
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
      >

        <el-table-column
          prop="date"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Issue Date & Time</span>
              <v-date-picker placeholder="Range"></v-date-picker>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>ID</span>
              <v-input placeholder="Search"></v-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Referrer</span>
              <v-input placeholder="Search"></v-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Product Type </span>
              <v-select></v-select>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Insurer</span>
              <v-input placeholder="Search"></v-input>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Commission</span>
              <span class="block-table"></span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Client Type</span>
              <v-select></v-select>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policyholder</span>
              <v-input placeholder="Search"></v-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col>
                  <span class="is-bold">Issue Date & Time</span>
                  <v-date-picker></v-date-picker>
                </v-row-col>

                <v-row-col>
                  <span class="is-bold">ID</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Referrer</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Product Type</span>
                  <v-select></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Insurer</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>

                <v-row-col>
                  <span class="is-bold">Commission</span>
                  <v-select></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Client Type</span>
                  <v-select></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policyholder</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
          class="tr">
          <span>Issue Date & Time</span>
          <span>{{item.date}}</span>
        </div>

        <div
          class="tr">
          <span>ID</span>
          <span>{{item.date}}</span>
        </div>
        <div
          class="tr">
          <span>Referrer</span>
          <span>{{item.name}}</span>
        </div>

        <div
          class="Jtr">
          <span>Joined Date & Time</span>
          <span>{{item.date}}</span>
        </div>

        <div
          class="tr">
          <span>No. of Referred Policies</span>
          <span>{{item.date}}</span>
        </div>

        <div
          class="tr">
          <span>No. of Policies</span>
          <span>{{item.date}}</span>
        </div>
        <div
          class="tr">
          <span>No. of Policies</span>
          <span>{{item.date}}</span>
        </div>

        <div
          class="tr">
          <span>Client Type</span>
          <span>{{item.date}}</span>
        </div>
        <div
          class="tr"
        >
          <span>Policyholder</span>
          <span>{{item.date}}</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
  </section>
</template>

<script>
import {getReferrerList} from '@/api/referrer'
export default {
  name: 'referral',
  props:{
  },
  data(){

    return {
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10
      },
      tableData: [{
        date: '2016-05-02',
        type:'New',
        name: 'OCY Company Name Ltd',
        no: '91231234',
        status:'draft'
      }, {
        date: '2016-05-02',
        type:'New',
        name: 'OCY Company Name Ltd',
        no: '91231234',
        status:'draft'
      }, {
        date: '2016-05-02',
        type:'New',
        name: 'OCY Company Name Ltd',
        no: '91231234',
        status:'draft'
      }, {
        date: '2016-05-02',
        type:'New',
        name: 'OCY Company Name Ltd',
        no: '91231234',
        status:'Pending Payment'
      }]
    }
  },
  computed:{
    showIssue(){
      return this.type==1||this.type==5
    },
    showEffective(){
      return this.type==1
    },
    showPeriod(){
      return this.type==4||this.type==6||this.type==5
    },
    showTell(){
      return this.type==4||this.type==5||this.type==6||this.type==3
    },
    showCreateDate(){
      return this.type ==4||this.type==2
    },
    showExipredDate(){
      return this.type ==3
    },
    //type\contact no\status
    showType(){
      return this.type ==1||this.type ==2
    }
  },
  methods:{
    rowClick(){
      this.$router.push('/policy/draft/info')
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().getReferrerList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().getReferrerList()
    },
    network() {
      return {
        getReferrerList: async () => {
          const { data } = await getReferrerList({
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          this.tableData = data.data
          this.pageConfig.total = data.total
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList'
</style>
